// Addon live server api
export const BASEURL = "http://52.164.202.12/vendor-api";
export const API: $TSFixMe = {
  //Auth API
  LOGIN_API: "/api/v1/login",
  REFRESH_TOKEN_API: "/api/v1/access-token",
  LOGOUT_API: "/api/v1/logout",
  FORGOT_PASSWORD: "/api/v1/auth/send-forgot-password-email",
  RESET_PASSWORD: "/api/v1/auth/reset-password",
  UPDATE_PASSWORD: "/api/v1/vendor-user/update-password",

  //User Config.
  USER_CONFIG: "/api/v1/user-configuration",
  USER_PROFILE: "/api/v1/user-configuration/upload-profile",
  TIME_ZONE_LIST: "/api/v1/resource/time-zones",

  //  dropdowns
  DEVICE_LIST_DD: "/api/v1/device-management/dd",
  PROJECT_LIST_DD: "/api/v1/project/dd",
  DRIVER_LIST_DD: "/api/v1/driver-management/dd",
  USER_ROLE_LIST_DD: "/api/v1/management-role/dd",

  // gps 
  GPS_MONITORING: "/api/v1/iot/gps/monitor",
  LIVE_TRACKING: "/api/v1/iot/gps/live-tracking",
  VEHICLE_JOURNEY: "/api/v1/iot/gps/journey",
  MONITOR_MARKER_DATA: "/api/v1/iot/gps/monitor-device-details",

  //UPLOAD API
  UPLOAD_PROJECT_LOGO: "/api/v1/project/upload-logo",
  PROFILEAPI: "/app/getUserProfile",
  DIALCODE: "/api/v1/resource/countries/dailcode",

  //Vendor User API
  VENDOR_USER: "/api/v1/vendor-user",

  // Management & Settings
  MANAGEMENT_ROLE: "/api/v1/management-role",
  RESOURCE_ROLES: "/api/v1/resource/roles",
  USERS_MANAGEMENT: "/api/v1/ct-admin-management",
  DEVICE_MANAGEMENT: "/api/v1/device-management",
  DEVICE_MANAGEMENT_FOR_PROJECT: "/api/v1/device-management/project",
  DEVICE_PART_INFORMATION: "/api/v1/device-part-information",
  DRIVER_MANAGEMENT: "/api/v1/driver-management",
  DRIVER_UNDER_PROJECT: "/api/v1/driver-management/project",
  PROJECT_MANAGEMENT: "/project-management",
  ORGANIZATION_MANAGEMENT: "/api/v1/organization-management",
  ORGANIZATION_MANAGEMENT_TOTAL_DEVICE:
    "/api/v1/organization-management/total-device",
  SUPPORT_TICKET: "/api/v1/support-ticket",
  PROJECT_API: "/api/v1/project",
  PROJECT_UNDER_ORGANIZATION: "/api/v1/project/organization",
  COMMON_CONFIGURATION: "/api/v1/common-configuration-template",
  COMMON_CONFIGURATION_DETAILS: "/api/v1/common-configuration-template/details",
  COMMON_CONFIGURATION_ADD: "/api/v1/common-configuration-template/save-details",
  COMMON_CONFIGURATION_UPDATE: "/api/v1/common-configuration-template/update-details",
  COMMON_CONFIGURATION_ASSIGN_DEVICE: "/api/v1/common-configuration-template/assign-device",
  COMMON_CONFIGURATION_VERIFY_DEVICE: "/api/v1/common-configuration-template/verify-device",
  COMMON_CONFIGURATION_IMEI: "/api/v1/common-configuration-template/device-configuration",

  // Events & Videos
  SA_AS_EVENTS: "/api/v1/iot/saas-event",
  HIGH_EVENTS: "/api/v1/iot/high-event",
  HIGH_EVENTS_BY_ID: "/api/v1/iot/high-event/byid",
  LOW_EVENTS: "/api/v1/iot/low-event",
  LOW_EVENTS_BY_ID: "/api/v1/iot/low-event/byid",
  REQUESTED_VIDEO: "/api/v1/iot/request-video",
  REQUEST_VIDEO: "/api/v1/iot/request-video",
  GEO_FENCE_EVENTS: "/api/v1/iot/geo-fence-event",
  TAMPER_EVENTS: "/api/v1/iot/tempare-event",
  PANIC_BUTTON_EVENTS: "/api/v1/iot/panic-event",
  ACTIVE_SAFETY_EVENTS: "/api/v1/iot/active-safety",
  LOW_EVENT_VIDEO_REQUEST: "/api/v1/iot/low-event/video-request",
  LOW_EVENT_VIDEO_INFO: "/api/v1/iot/low-event/video-information",
  HIGH_EVENT_VIDEO_INFO: "/api/v1/iot/high-event/video-information",

  ACTIVE_SAFETY_EVENTS_INFO_SPEED_LOCATION: "/api/v1/iot/active-safety/speed-location",
  ACTIVE_SAFETY_EVENTS_INFO: "/api/v1/iot/active-safety/video-information",
  ACTIVE_SAFETY_EVENTS_INFO_ADMIN_NOTE: "/api/v1/iot/active-safety/admin-note",
  ACTIVE_SAFETY_EVENTS_INFO_GYRO: "/api/v1/iot/active-safety/acceleration-information",

  HIGH_EVENT_VIDEO_INFO_GYRO: "/api/v1/iot/high-event/acceleration-information",
  LOW_EVENT_VIDEO_INFO_GYRO: "/api/v1/iot/low-event/acceleration-information",
  REQUESTED_VIDEO_INFO_GYRO: "/api/v1/iot/request-video/accelaration-information",
  PANIC_BUTTON_INFO_GYRO: "/api/v1/iot/panic-event/acceleration-information",
  DEVICE_STATUS_VIDEO_INFO_GYRO: "/api/v1/device-status/acceleration-information",
  PANIC_BUTTON_INFO_SPEED_LOCATION: "/api/v1/iot/panic-event/speed-location",

  DEVICE_STATUS_VIDEO_INFO: "/api/v1/device-status/video-information",
  REQUESTED_VIDEO_INFO: "/api/v1/iot/request-video/video-information",
  PANIC_BUTTON_INFO: "/api/v1/iot/panic-event/video-information",
  LOW_EVENT_VIDEO_INFO_SPEED_LOCATION: "/api/v1/iot/low-event/speed-location",
  HIGH_EVENT_VIDEO_INFO_SPEED_LOCATION: "/api/v1/iot/high-event/speed-location",
  REQUESTED_VIDEO_INFO_SPEED_LOCATION: "/api/v1/iot/request-video/speed-location",
  DEVICE_STATUS_VIDEO_INFO_SPEED_LOCATION: "/api/v1/device-status/speed-location",
  GPS_INFORMATION: "/api/v1/iot/geo-fence-event/gps-information",
  GEO_FENCE_EVENT: "/api/v1/iot/geo-fence-event/geo-fence",

  // admin note
  LOW_EVENT_VIDEO_INFO_ADMIN_NOTE: "/api/v1/iot/low-event/admin-note",
  HIGH_EVENT_VIDEO_INFO_ADMIN_NOTE: "/api/v1/iot/high-event/admin-note",
  REQUESTED_VIDEO_INFO_ADMIN_NOTE: "/api/v1/iot/request-video/admin-note",
  PANIC_BUTTON_INFO_ADMIN_NOTE: "/api/v1/iot/panic-event/admin-note",

  // DriverStatistics
  DRIVER_STATISTICS: "/api/v1/driver-statistic",
  HARSH_EVENTS: "/api/v1/driver-statistic/harsh-events",
  HOURS_PER_DAY: "/api/v1/driver-statistic/hours-per-day",
  DRIVER_STATISTICS_JOURNEY: "/api/v1/driver-statistic/journey",

  // Trips&Geo-fence
  VEHICLE_TRIP: "/api/v1/reports/vehicle-trips",
  GEO_FENCING: "/api/v1/geofence",
  VEHICLE_TRIP_LOW_EVENT: "/api/v1/iot/trip/low-event",
  VEHICLE_TRIP_HIGH_EVENT: "/api/v1/iot/trip/high-event",
  TRIP_VIDEO_REQUEST: "/api/v1/iot/trip/video-request",
  // Reports
  EVENT_REPORTS: "/api/v1/reports/events",
  VIDEO_REQUEST_REPORTS: "/api/v1/reports/video-request",
  DEVICE_REPORTS: "/api/v1/reports/devices",
  LOCATION_REPORTS: "/api/v1/reports/location",
  ORGANIZATION_REPORTS: "/api/v1/device-management",
  VEHICLE_TRIP_REPORTS: "/api/v1/device-management",
  VEHICLE_TRIP_LIST: "/api/v1/iot/trip",
  VEHICLE_TRIP_DETAIL: "/api/v1/iot/trip/detail",
  TRIP_RECALCULATE: "/api/v1/iot/trip/recalculate",
  VEHICLE_TRIP_GPS: "/api/v1/iot/trip/gps",

  // AllDevices
  DEVICE_STATUS: "/api/v1/device-status",
  DEVICE_RESET: "/api/v1/device-status/reset-data",
  DEVICE_VIDEO_REQUEST: "/api/v1/device-status/video-request",
  HEALTH_CHECK: "/api/v1/device-status/heath-check",
  GET_DEVICE_LOG: "/api/v1/device-status/device-log",
  LOGIN_BY_TOKEN: "/api/v1/login-by-token",

  // notifications
  NOTIFICATION_LIST: "/api/v1/notification/list",
  NOTIFICATION_COUNT: "/api/v1/notification/count",
  NOTIFICATION_CLEAR: "/api/v1/notification/clear",
  NOTIFICATION_READ: "/api/v1/notification/mark-as-read",
  NOTIFICATION_READ_ALL: "/api/v1/notification/mark-all-read",

  //DashBoard
  GLOBAL_SEARCH: "/api/v1/dashboard/global-search",
  ONLINE_DEVICE: "/api/v1/dashboard/online-device",
  TOTAL_DEVICE: "/api/v1/dashboard/total-device",
  TOTAL_MILES_PROTECTED: "/api/v1/dashboard/total-miles-protected",
  VEHICLE_EVENT_EXCEPTION_SUMMARY: "/api/v1/dashboard/vehicle-event-exception-summary",
  PROJECT_SPLIT: "/api/v1/dashboard/project-split",
  TOP_DRIVER: "/api/v1/dashboard/top-driver",

  // resource
  FACTORY_SETTINGS: "/api/v1/resource/restore-factory-setting",
  WAKE_UP_CONDITION: "/api/v1/resource/wake-up-condition",
  WAKE_UP_CONDITION_TIME: "/api/v1/resource/wake-up-condition-time",
  WARRANTY_OPTION: "/api/v1/resource/warrenty-option",
  DRIVER_RFID_LIST: "/api/v1/common-configuration-template/driver-rfid-configuration/drivers",
};
